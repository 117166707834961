import React, { Component } from 'react'
import { Document, Page, pdfjs } from 'react-pdf';
import NewsService from '../Service';
import Navbar from "../Navbar/index";
import Secondnavbar from '../Navbar/Secondnavbar';
import { URLs } from "../../../constants/constants";
import Util from "../../../util";
import './styles.scss'
import { baseUrl } from '../../../services/services';
import NewsFeedAd from '../../common/advertise/NewsFeedAd';
import axios from 'axios';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;





export default class EPaperView extends Component {
    constructor(props) {
        super(props)
        let routeInfo = this.props.match;

        this.state = {
            paperId: routeInfo.params.paperId,
            editionId: routeInfo.params.editionId,
            edition: {},
            epaper: [],
            totalPage: 0,
            currentPage: 1,
            scale: 0.75,
            // "epaper": {
            //     "date": "2022-01-02T18:30:00.000Z",
            //     papers: [{
            //         paperType: 'Main',
            //         paperUrl: '/uploads/fs09012022/file_164117097235901012022Shanarthitelanganareport.pdf'

            //     },
            //     {
            //         paperType: 'Movie',
            //         paperUrl: '/uploads/fs09012022/file_164117082948001012022Shanarthitelanganareport.pdf'

            //     }
            //     ]
            // }

        }

        this.newsService = new NewsService();

    }

    onDocumentLoadSuccess = (data) => {
        console.log(data);
        // numPages
        //setNumPages(numPages);
        this.setState({
            totalPage: data.numPages
        });
    }


    onItemClick = (data) => {
        console.log(data);
    }

    getPaper = () => {
        this.setState({
            isLoading: true
        });

        this.newsService.getDataOrError(`${URLs.getEPaperById}/${this.state.paperId}`, false).then((res) => {

            if (res.status) {
                if (res && res.data) {
                    let epaper = res.data.papers.filter((a) => a._id == this.state.editionId);
                    this.setState({
                        edition: epaper[0],
                        isLoading: false,
                        epaper: res.data
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            }
            else {
                this.setState({
                    isLoading: false
                });
            }
        });
    }

    componentDidMount() {
        this.getPaper();

        // window.onscroll = function () { myFunction() };

        // var navbar = document.getElementById("navbar");
        // var sticky = navbar.offsetTop;
        // 
        // function myFunction() {
        //     if (window.pageYOffset >= sticky) {
        //         navbar.classList.add("sticky")
        //     } else {
        //         navbar.classList.remove("sticky");
        //     }
        // }
    }

    _onScrollEvent = () => {

        var navbar = document.getElementById("navbar");
        if (navbar) {


            var sticky = navbar.offsetTop;
            if (window.pageYOffset >= sticky) {
                navbar.classList.add("sticky")
            } else {
                navbar.classList.remove("sticky");
            }
        }
    }

    onDownloadClick = () => {


        let epaperInfo = this.state.edition;

        let url = `${baseUrl}${epaperInfo.paperUrl}`;

        this.handleDownload(url)
    }

    handleDownload = (url, filename) => {

        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {

                const url = window.URL.createObjectURL(
                    new Blob([res.data]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `FileName.pdf`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
                // fileDownload(res.data, filename)
            })
    }




    componentDidUpdate() {
        window.addEventListener('scroll', this._onScrollEvent);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this._onScrollEvent, { capture: false });
    }

    render() {
        console.log(this.state)
        return (
            <>
                <Navbar afterLanguageChange={this.reRender} />
                <Secondnavbar />
                <div className=" main-container" >
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-md2 ms-lg2 ms-hiddenMdDown">
                                <NewsFeedAd />
                                <NewsFeedAd />
                                <NewsFeedAd />
                            </div>
                            <div className="ms-Grid-col ms-md8 ms-lg8">
                                <div id="navbdar">
                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col">
                                                <div class="btn-group">
                                                    <button className='sp-pointer btn-f' onClick={() => {
                                                        this.setState({
                                                            currentPage: 1
                                                        })
                                                    }}>
                                                        <i className="ms-Icon ms-Icon--DoubleChevronLeftMed" aria-hidden="true"></i>

                                                    </button>
                                                    <button
                                                        disabled={this.state.currentPage == 1}
                                                        onClick={() => {
                                                            this.setState({
                                                                currentPage: this.state.currentPage - 1
                                                            })
                                                        }}>
                                                        <i className="ms-Icon ms-Icon--ChevronLeft" aria-hidden="true"></i>
                                                    </button>

                                                    <button className='page-btn'>
                                                        Page {this.state.currentPage} of {this.state.totalPage}
                                                    </button>

                                                    <button
                                                        disabled={this.state.currentPage == this.state.totalPage}
                                                        onClick={() => {
                                                            this.setState({
                                                                currentPage: this.state.currentPage + 1
                                                            })
                                                        }}>
                                                        <i className="ms-Icon ms-Icon--ChevronRight" aria-hidden="true"></i>
                                                    </button>
                                                    <button className='btn-l' onClick={() => {
                                                        this.setState({
                                                            currentPage: this.state.totalPage
                                                        })
                                                    }}>
                                                        <i className="ms-Icon ms-Icon--DoubleChevronRight" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="ms-Grid-col">
                                                <div class="btn-group">
                                                    <button className='sp-pointer btn-f' disabled={!(this.state.scale > 0.3)} onClick={() => {
                                                        this.setState({
                                                            scale: this.state.scale - 0.2
                                                        })
                                                    }}>
                                                        <i className="ms-Icon ms-Icon--ZoomOut " aria-hidden="true"></i>
                                                    </button>
                                                    <button className='sp-pointer' disabled={!(this.state.scale < 5)} onClick={() => {
                                                        this.setState({
                                                            scale: this.state.scale + 0.2
                                                        })
                                                    }}>
                                                        <i className="ms-Icon ms-Icon--ZoomIn " aria-hidden="true"></i>

                                                    </button>
                                                    <button className='sp-pointer btn-l' onClick={this.onDownloadClick}>
                                                        <i className="ms-Icon ms-Icon--CloudDownload" aria-hidden="true"></i>

                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* {
                                    this.state.currentPage > 1 &&
                                    <span onClick={() => {
                                        this.setState({
                                            currentPage: this.state.currentPage - 1
                                        })
                                    }}>
                                        <i className="ms-Icon ms-Icon--Forward sp-pointer" aria-hidden="true"></i>
                                    </span>}

                                {
                                    this.state.currentPage < this.state.totalPage &&
                                    < span onClick={() => {
                                        this.setState({
                                            currentPage: this.state.currentPage + 1
                                        })
                                    }}>next</span>}

                                {
                                    this.state.scale < 5 && <span onClick={() => {
                                        this.setState({
                                            scale: this.state.scale + 0.2
                                        })
                                    }}>in</span>}
                                <span>{(this.state.scale * 100).toFixed(0)}%</span>
                                {
                                    this.state.scale > 0.3 && <span onClick={() => {
                                        this.setState({
                                            scale: this.state.scale - 0.2
                                        })
                                    }}>de</span>
                                }

                                <span>
                                    Page {this.state.currentPage} of {this.state.totalPage}
                                </span> */}
                                </div>
                                <div>
                                    <Document
                                        file={`${baseUrl}${this.state.edition.paperUrl}`}
                                        // file={'/file_164143395365506012022Shanarthitelanganareportcompressed.pdf'}
                                        onLoadSuccess={this.onDocumentLoadSuccess}
                                        onItemClick={this.onItemClick}
                                    //renderMode="svg"
                                    >
                                        <Page className="test"
                                            //width={1000}
                                            scale={this.state.scale}
                                            height={2000}

                                            pageNumber={this.state.currentPage} />
                                    </Document>
                                </div>
                            </div>
                            <div className="ms-Grid-col ms-md2 ms-lg2">
                                <NewsFeedAd />
                                <NewsFeedAd />
                                <NewsFeedAd />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
