import * as React from "react";
import {
    RouteComponentProps, withRouter
} from 'react-router-dom';
import './styles.scss';
import NewsService from '../../Service';
import { INewsInfoC, MainNewsModel } from '../../../../models/models';
import Actions from "../compose";
import { socket } from '../../../../app';
import ImageCarousel from "../templates/ImageCarousel";
import { AppState } from "../../../../Redux/app.store";
import { connect } from "react-redux";
import { IUserState } from "../../../../Redux/models";
import { Shimmer3 } from "../../../common/Loading/Shimmers";
import Analysis from "../templates/Analysis";
import Donations from "../Donations";
import { URLs } from "../../../../constants/constants";
import NewsFeedAd from "../../../common/advertise/NewsFeedAd";
import Util from "../../../../util";
import Survey from "./Survey";

interface IState {
    allNews: INewsInfoC[];
    isLoading: boolean;
    currentScrollPosition: number;
    skip: number;
    isLoadNext: boolean;
    DateTime: any;
    stopRequests: boolean;
}

interface IProps extends RouteComponentProps {
    User: IUserState;
}

class MainNews extends React.Component<IProps, IState> {
    private newsService: NewsService;
    private language: string;
    currentLanguage = localStorage.getItem('language');
    constructor(props: IProps) {
        super(props);
        this.state = {
            isLoading: false,
            allNews: [],
            currentScrollPosition: 0,
            skip: 0,
            isLoadNext: false,
            DateTime: null,
            stopRequests: false
        }
        this.newsService = new NewsService();
        this.language = this.currentLanguage ? this.currentLanguage : 'en';
        this._onScrollEvent = this._onScrollEvent.bind(this);
    }

    componentWillReceiveProps(newProps: IProps) {
        if (this.language !== newProps.User.language) {
            this.language = newProps.User.language;
            this.getData();
        }
    }

    private getData() {
        this.setState({ isLoading: true, allNews: [] });
        this.newsService.postDataOrError(URLs.getNewsForUserHomePage, { skip: 0 }, true, false).then((res: any) => {
            if (res.status) {
                let data = res.data;
                this.setState({
                    allNews: data,
                    isLoading: false,
                    currentScrollPosition: window.scrollY,
                    skip: data.length,
                    DateTime: res.data.length > 0 ? res.data[0].DateTime : ''
                });
            } else {
                this.setState({ isLoading: false });
            }
        });
    }

    componentDidMount() {
        this.getData();

        socket.on("NewsUpdated", (data: any) => {
            let newsInfo: any;
            let allNews: INewsInfoC[] = this.state.allNews;
            let tempNews: INewsInfoC[] = [];
            if (this.language === 'en') {
                newsInfo = data.English;
            } else {
                newsInfo = data.Telugu;
            }

            allNews.forEach((news: INewsInfoC) => {
                if (news._id === newsInfo._id) {
                    if (newsInfo.Show) {
                        tempNews = [...tempNews, this.language == 'en' ? new MainNewsModel(newsInfo) : newsInfo]
                    }
                } else {
                    tempNews = [...tempNews, news];
                }
            });
            this.setState({
                allNews: tempNews
            });
        });
    }

    _onScrollEvent() {
        const totalHeight = document.documentElement.scrollHeight;
        if (window.pageYOffset > totalHeight - 3000 && !this.state.isLoading && !this.state.stopRequests) {
            this.setState({
                isLoading: true
            });
            this.newsService.postDataOrError(URLs.getLatestNews, { skip: this.state.skip, filter: { DateTime: this.state.DateTime } }, true, false).then((res: any) => {
                if (res.status) {
                    let data: Array<any> = res.data;
                    if (data.length > 0) {
                        let allre: INewsInfoC[] = this.state.allNews;
                        data.forEach((d: any) => {
                            allre = [...allre, d];
                        });
                        this.setState((prevState: IState) => {
                            return {
                                allNews: allre,
                                currentScrollPosition: window.scrollY,
                                skip: data.length > 0 ? prevState.skip + data.length : prevState.skip,
                                isLoading: false,
                                DateTime: prevState.DateTime < res.data[0].DateTime ? res.data[0].DateTime : prevState.DateTime
                            }
                        });
                    } else {
                        this.setState({ isLoading: false, stopRequests: true });
                    }
                } else {
                    this.setState({ isLoading: false, stopRequests: true });
                }
            });
        }
    }

    componentDidUpdate() {
        window.addEventListener('scroll', this._onScrollEvent);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this._onScrollEvent, { capture: false });
        this.setState({
            allNews: []
        });
    }

    render(): JSX.Element {
        return (
            <div className="main-container-wrapper">
                {/* <Actions /> */}
                <Donations />
                {/* <Survey /> */}
                {/* <ImageCarousel /> */}
                {this.state.allNews && this.state.allNews.length > 0 &&
                    this.state.allNews.map((news: INewsInfoC, index: number) => {
                        if (index % Util.getRandomNumber(3, 5) == 0) {
                            return <div id={news._id}><div className="news-feed-ad"><NewsFeedAd cid={news._id.substring(1, 8)} /> </div>
                                <div id={news._id}><Analysis news={news} /></div>
                            </div>
                        } else {
                            return <div id={news._id}><Analysis news={news} /></div>
                        }

                    })
                }
                {this.state.isLoading && <>
                    <div className="shimmer-main-w"> <Shimmer3 /> </div>
                    <div className="shimmer-main-w"> <Shimmer3 /> </div>
                </>}
            </div>
        );
    }
}


const mapStateToProps = (state: AppState): AppState => ({
    User: state.User,
});

export default withRouter(connect(
    mapStateToProps,
)(MainNews));