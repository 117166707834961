import * as React from "react";
import { DefaultButton, TextField } from 'office-ui-fabric-react';
import { Dialog, DialogType } from 'office-ui-fabric-react/lib/Dialog';
import { FormType, ILoginForm, ILoginFormErr } from '../../../models/models';
import AuthService from '../../../services/authService/index';
import { AppState } from "../../../Redux/app.store";
import { connect } from "react-redux";
import { IUserState } from "../../../Redux/models";
import Loading from "../../common/Loading";
import './styles.scss';

interface IOTPVerifyForm {
    otp: string;
    otpErr: string;
}

interface IState {
    otpVerifyForm: IOTPVerifyForm;
    loginForm: ILoginForm;
    loginFormErr: ILoginFormErr;
    FormErrMessage: string;
    language: string;
    showOTPDialog: boolean;
    showResendButton: boolean;
    isResendOtp: boolean;
    isLoading: boolean;
}

interface IProps {
    showDialog: boolean;
    afterLogin(): void;
    formHandle(id: number, userLoginId?: string): void;
    User: IUserState;
}

class LoginForm extends React.Component<IProps, IState> {
    private authService: AuthService;
    constructor(props: IProps) {
        super(props);
        this.state = {
            loginForm: { phoneNumber: '', password: '' },
            loginFormErr: { phoneNumberErr: '', passwordErr: '' },
            FormErrMessage: '',
            language: this.props.User.language,
            showOTPDialog: false,
            otpVerifyForm: { otp: '', otpErr: '' },
            showResendButton: false,
            isResendOtp: false,
            isLoading: false
        }
        this._passwordChangeHandle = this._passwordChangeHandle.bind(this);
        this._submitForm = this._submitForm.bind(this);
        this._submitOtp = this._submitOtp.bind(this);
        this._phoneNumberChangeHandle = this._phoneNumberChangeHandle.bind(this);
        this._otpChangeHandle = this._otpChangeHandle.bind(this);
        this._resendOTP = this._resendOTP.bind(this);
        this.authService = new AuthService();
    }


    private _resendOTP() {
        this.authService.resendOTP({ phoneNumber: this.state.loginForm.phoneNumber }).then((res: any) => {
            if (res.status === true) {
                if (res.statuscode === 1) {
                    this.setState({
                        showResendButton: false,
                        isResendOtp: true
                    });
                    setTimeout(() => {
                        this.setState({
                            isResendOtp: false
                        });
                    }, 7000);
                }
            } else {
                this.setState({
                    FormErrMessage: res.message
                });
            }
        });
    }

    private _passwordChangeHandle = (event: React.ChangeEvent<HTMLInputElement>): void => {
        let errorMessage: string;
        if (event.target.value === "") {
            errorMessage = this.props.User.staticConstants.Constants.required;
        } else {
            errorMessage = "";
        }
        this.setState({
            loginForm: { ...this.state.loginForm, [event.target.name]: event.target.value },
            loginFormErr: { ...this.state.loginFormErr, [event.target.name + 'Err']: errorMessage }
        });
    }

    private _phoneNumberChangeHandle = (event: React.ChangeEvent<HTMLInputElement>): void => {
        let errorMessage: string;
        const phoneNumber = (event.target.validity.valid) ? event.target.value : this.state.loginForm.phoneNumber;
        if (event.target.value === "") {
            errorMessage = errorMessage = this.props.User.staticConstants.Constants.required;
        } else if (!event.target.validity.valid) {
            errorMessage = this.props.User.staticConstants.Constants.onlyNumbersAllowed;
        } else {
            errorMessage = "";
        }
        this.setState({
            loginForm: { ...this.state.loginForm, [event.target.name]: phoneNumber },
            loginFormErr: { ...this.state.loginFormErr, [event.target.name + 'Err']: errorMessage }
        });
    }

    private isFormValid = (): boolean => {
        let loginForm: ILoginForm = this.state.loginForm;
        let loginFormErr: ILoginFormErr = this.state.loginFormErr;
        let isFormValid: boolean = true;
        if (loginForm.phoneNumber === "") {
            loginFormErr.phoneNumberErr = this.props.User.staticConstants.Constants.required;
            isFormValid = false;
        } else if (loginForm.phoneNumber.length < 10) {
            loginFormErr.phoneNumberErr = this.props.User.staticConstants.Constants.enterValidNumber;
            isFormValid = false;
        } else {
            loginFormErr.phoneNumberErr = "";
        }
        if (loginForm.password === "") {
            loginFormErr.passwordErr = this.props.User.staticConstants.Constants.required;
            isFormValid = false;
        } else {
            loginFormErr.passwordErr = "";
        }
        this.setState({
            loginFormErr: loginFormErr,
        });
        return isFormValid;
    }

    private _submitForm() {
        if (this.isFormValid()) {
            this.setState({ isLoading: true });
            this.authService.Login(this.state.loginForm).then((res: any) => {
                if (res.status === true) {
                    this.setState({
                        loginForm: { phoneNumber: '', password: '' },
                        loginFormErr: { phoneNumberErr: '', passwordErr: '' },
                        FormErrMessage: '',
                        isLoading: false
                    });
                } else {
                    if (res.statuscode === 2) {
                        this.setState({
                            showOTPDialog: true,
                            isLoading: false
                        });

                        setTimeout(() => {
                            this.setState({
                                showResendButton: true
                            });
                        }, 30000);

                    } else {
                        if (this.state.language === 'en') {
                            this.setState({
                                FormErrMessage: res.message,
                                isLoading: false
                            });
                        } else {
                            if (res.statuscode === 6) {
                                this.setState({
                                    FormErrMessage: this.props.User.staticConstants.Constants.invalidPassword,
                                    isLoading: false
                                });
                            } else if (res.statuscode === 3) {
                                this.setState({
                                    FormErrMessage: this.props.User.staticConstants.Constants.rejectedError,
                                    isLoading: false
                                });
                            } else if (res.statuscode === 4) {
                                this.setState({
                                    FormErrMessage: this.props.User.staticConstants.Constants.suspendedError,
                                    isLoading: false
                                });
                            } else if (res.statuscode === 7) {
                                this.setState({
                                    FormErrMessage: this.props.User.staticConstants.Constants.accountNotFoundError,
                                    isLoading: false
                                });
                            } else {
                                this.setState({
                                    FormErrMessage: this.props.User.staticConstants.Constants.error,
                                    isLoading: false
                                });
                            }
                        }
                        setTimeout(() => {
                            this.setState({
                                FormErrMessage: ''
                            });
                        }, 5000);
                    }
                }
            });
        }
    }

    private _isOtpValid = (): boolean => {
        let otpVerifyForm: IOTPVerifyForm = this.state.otpVerifyForm;
        let isFormValid: boolean = true;
        if (otpVerifyForm.otp === "") {
            otpVerifyForm.otpErr = this.props.User.staticConstants.Constants.required;
            isFormValid = false;
        }
        else {
            otpVerifyForm.otpErr = "";
        }
        this.setState({
            otpVerifyForm: otpVerifyForm,
        });
        return isFormValid;
    }

    private _submitOtp() {
        if (this._isOtpValid()) {
            this.setState({
                isLoading: true
            });

            this.authService.verifyOTP({ phoneNumber: this.state.loginForm.phoneNumber, otp: this.state.otpVerifyForm.otp }).then((res: any) => {
                if (res.status === true) {
                    this.authService.Login(this.state.loginForm).then((res: any) => {
                        if (res.status === true) {
                            this.setState({
                                loginForm: { phoneNumber: '', password: '' },
                                loginFormErr: { phoneNumberErr: '', passwordErr: '' },
                                FormErrMessage: '',
                                otpVerifyForm: { otp: '', otpErr: '' },
                                isLoading: false
                            });
                        }
                    });
                } else {
                    this.setState({
                        FormErrMessage: res.message,
                        isLoading: false
                    });
                    setTimeout(() => {
                        this.setState({
                            FormErrMessage: ''
                        });
                    }, 5000);
                }
            });
        }
    }

    private _otpChangeHandle = (event: React.ChangeEvent<HTMLInputElement>): void => {
        let erorMessage: string;
        const otp = (event.target.validity.valid) ? event.target.value : (event.target.validationMessage === "Please fill out this field." ? '' : this.state.otpVerifyForm.otp);
        if (event.target.value === "") {
            erorMessage = "OTP is required";
        } else if (!event.target.validity.valid) {
            erorMessage = "Please enter numbers only.";
        }
        else {
            erorMessage = "";
        }
        this.setState({
            otpVerifyForm: { ...this.state.otpVerifyForm, [event.target.name]: otp, [event.target.name + 'Err']: erorMessage }
        });
    }


    render(): JSX.Element {
        return (<>
            {this.state.isLoading && <Loading />}
            <Dialog
                hidden={!this.props.showDialog}
                onDismiss={() => { this.props.afterLogin() }}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: this.state.showOTPDialog ? this.props.User.staticConstants.Constants.verifyMobile : this.props.User.staticConstants.Constants.loginTitle,
                    closeButtonAriaLabel: 'Close',
                    showCloseButton: true,
                }}
                modalProps={{
                    containerClassName: "login-container-w",
                    onDismissed: () => { this.props.afterLogin() },
                    isBlocking: true
                }} >
                {!this.state.showOTPDialog ?
                    <div className="login-page">
                        <input type="text" pattern="[0-9]*" maxLength={10} className={`sp-input first ${true ? 'error-border' : ''}`} placeholder={this.props.User.staticConstants.Constants.phoneNumber} name="phoneNumber" value={this.state.loginForm.phoneNumber} onChange={this._phoneNumberChangeHandle} />
                        <p className="sp-danger">{this.state.loginFormErr.phoneNumberErr}</p>
                        <input type="password" className={`sp-input second ${true ? 'error-border' : ''}`} placeholder={this.props.User.staticConstants.Constants.password} name="password" value={this.state.loginForm.password} onChange={this._passwordChangeHandle} />
                        <p className="sp-danger">{this.state.loginFormErr.passwordErr}</p>
                        <p className="sp-danger">{this.state.FormErrMessage}</p>
                        <a className="forget-password" onClick={() => this.props.formHandle(FormType.ForgetPassword)} >{this.props.User.staticConstants.Constants.forgetPassword}</a>
                        <div className="sp-clearFix"></div>
                        <p className="create-account">{this.props.User.staticConstants.Constants.newToOurSite}<a className="register" onClick={() => this.props.formHandle(FormType.Register)}>{this.props.User.staticConstants.Constants.createAccount}</a> </p>
                        <DefaultButton onClick={this._submitForm} className="sp-main-btn sp-float-right btn-login" text={this.props.User.staticConstants.Constants.login} />
                    </div>
                    : <div className="login-page">
                        <p>{this.props.User.staticConstants.Constants.pleaseEnter6DigiiOtp}</p>
                        <TextField
                            label="OTP"
                            minLength={6}
                            maxLength={6}
                            pattern="[0-9]*"
                            name="otp"
                            errorMessage={this.state.otpVerifyForm.otpErr}
                            value={this.state.otpVerifyForm.otp}
                            onChange={(event: any) => this._otpChangeHandle(event)} required />
                        <p className="sp-danger">{this.state.FormErrMessage}</p>
                        {this.state.showResendButton && <> <p className="no-otp">{this.props.User.staticConstants.Constants.didNotRecieveOtp},<span onClick={this._resendOTP} >{this.props.User.staticConstants.Constants.resend}</span></p> </>}
                        {this.state.isResendOtp && <p className="otp-sent">{this.props.User.staticConstants.Constants.otpSent}</p>}
                        <DefaultButton onClick={this._submitOtp} className="sp-main-btn sp-float-right btn-login" text={this.props.User.staticConstants.Constants.send} />
                    </div>
                }
            </Dialog>
        </>
        );
    }
}

const mapStateToProps = (state: AppState): AppState => ({
    User: state.User,
});

export default connect(
    mapStateToProps,
)(LoginForm);