import * as React from "react";
import { baseUrl } from "../../../../../services/services";
import './styles.scss';

class AddView extends React.Component {
    public render(): JSX.Element {
        return (<>
            <div className="sp-img-card sp-card-m"  >
                <div className="card sp-card-img">
                    <img className="sp-img" src={`${baseUrl}/uploads/static_files/addimage.jpg`} alt="" />
                </div>
            </div>
        </>
        );
    }
}

export default AddView;