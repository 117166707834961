import * as React from "react";
import { URLs } from '../../../constants/constants';
import './styles.scss';
import { DefaultButton } from 'office-ui-fabric-react';
import { Dialog, DialogType } from 'office-ui-fabric-react/lib/Dialog';
import ReduxService from "../../../Redux/service";
import { Toggle, DatePicker, mergeStyleSets, PrimaryButton } from "office-ui-fabric-react";
import { baseUrl } from "../../../services/services";
import Service from '../service';
import { DayPickerStrings, firstDayOfWeek } from "../../common/FormSP/Models";
import moment from "moment";
import { IDialogPropss } from "../../../models/models";


let dummy = [
    {
        "paperType": "Main",
        "paperUrl": "/uploads/fs09012022/file_164117097235901012022Shanarthitelanganareport.pdf",
        "thumbnail": "/uploads/fs09012022/file_164143315308403012022Shanarthitelanganareportpage00011.jpg"
    },
    {
        "paperType": "Movie",
        "paperUrl": "/uploads/fs09012022/file_164117082948001012022Shanarthitelanganareport.pdf",
        "thumbnail": "/uploads/fs09012022/file_164143316153603012022Shanarthitelanganareportpage0004.jpg"
    }
]

let files = [
    {
        "paperType": "Main",
        "paperUrl": "",
        "thumbnail": ""
    }
    // ,
    // {
    //     "paperType": "Movie",
    //     "paperUrl": "",
    //     "thumbnail": ""
    // }
]
const controlClass = mergeStyleSets({
    control: {
        margin: '0 0 15px 0',
        maxWidth: '300px',
    },
});

interface IEPaperD {
    paperType: string;
    paperUrl: string;
    thumbnail: string;
}

interface IEPaper {
    date: string;
    papers: Array<IEPaperD>;
    errorMessage: string;
}


interface IState {
    epaper: IEPaper;
    errorMessage: string;

    DialogProps: IDialogPropss;
}

interface IProps {

}

class AddPaper extends React.Component<IProps, IState> {
    private service: Service;
    constructor(props: IProps) {
        super(props);
        this.state = {
            // epaper: {
            //     url: '',
            //     date: '',
            //     errorMessage: ''
            // }
            errorMessage: '',

            "epaper": {
                "date": new Date().toString(),
                papers: files,
                errorMessage: ''

            },
            DialogProps: { show: false, message: '' },

        }
        this.service = new Service();
        //  this._onSucess = this._onSucess.bind(this);
        this._onChangeHandler = this._onChangeHandler.bind(this);
        this._onProgress = this._onProgress.bind(this);
    }

    private _closeDialog = () => {
        this.setState({
            DialogProps: { show: false, message: '' }
        });
    }

    public _onSucess = (some: any) => {
        if (some && some.response && some.response.success) {
            let papers = JSON.parse(JSON.stringify(this.state.epaper.papers));
            papers = papers.map((p: IEPaperD) => {
                if (p.paperType == some.paperType) {
                    p.paperUrl = some.response.filePath;
                }
                return p;
            })
            this.setState({
                epaper: { ...this.state.epaper, papers }
            });
            // this.service.postDataOrLogout(URLs.updateMyProfile, {
            //     field: 'imagePath',
            //     data: { imagePath: some.response.filePath, }
            // }, true).then(() => {
            //     this.setState({
            //         epaper: { ...this.state.epaper, url: some.response.filePath }
            //     });

            // });
        }
    }

    public _onThumbSucess = (some: any) => {
        debugger
        if (some && some.response && some.response.success) {

            let papers = JSON.parse(JSON.stringify(this.state.epaper.papers));

            papers = papers.map((p: IEPaperD) => {
                if (p.paperType == some.paperType) {
                    p.thumbnail = some.response.filePath;
                }
                return p;
            })


            this.setState({
                epaper: { ...this.state.epaper, papers }
            });
            debugger;
            // this.service.postDataOrLogout(URLs.updateMyProfile, {
            //     field: 'imagePath',
            //     data: { imagePath: some.response.filePath, }
            // }, true).then(() => {
            //     this.setState({
            //         epaper: { ...this.state.epaper, url: some.response.filePath }
            //     });

            // });

        }
    }


    public _onProgress(some: any) {

    }

    _onChangeHandler(event: any, _onSucess: any, _onProgress: any) {
        let paperType = event.target.getAttribute('custom-a')
        if (event.target.files[0]) {
            debugger
            if (event.target.files[0].type === 'application/pdf') {
                let datae: any = event.target.files[0];
                if (datae && datae.size < 5242880) {
                    const data = new FormData();
                    data.append('file', event.target.files[0]);
                    var xhr = new XMLHttpRequest();
                    xhr.open("POST", `${baseUrl}/use${URLs.uploadFiles}`);
                    xhr.upload.addEventListener("progress", function (this, evt) {
                        if (evt.lengthComputable) {
                            let percentComplete: any = evt.loaded / evt.total;
                            let df: any = percentComplete.toFixed(2) * 100;
                            datae.progress = df.toFixed(0);
                            let db = datae;
                            db.progress = df.toFixed(0);
                            _onProgress(db);
                        }
                    }, false);
                    xhr.onloadstart = function (e) {

                    }
                    xhr.onloadend = function (e) {

                    }
                    xhr.send(data);
                    xhr.onreadystatechange =
                        function () {
                            if (this.readyState === 4 && this.status === 200) {
                                var res = JSON.parse(this.response);
                                datae.response = res;
                                var de = datae;
                                debugger
                                de.paperType = paperType;
                                _onSucess(de);
                            }
                        };
                } else {
                    event.target.value = null
                    this.setState({
                        errorMessage: 'Max size 5MB.'
                    });
                    setTimeout(() => {
                        this.setState({
                            errorMessage: ''
                        });
                    }, 3000);
                }

            } else {
                event.target.value = null
                this.setState({
                    errorMessage: 'Please select pdf only.'
                });
                setTimeout(() => {
                    this.setState({
                        errorMessage: ''
                    });
                }, 3000);
            }
        }
    }

    _onThumbNailChangeHandler(event: any, _onSucess: any, _onProgress: any) {
        let paperType = event.target.getAttribute('custom-a')
        if (event.target.files[0]) {
            if (event.target.files[0].type === 'image/png' || event.target.files[0].type === 'image/x-png' || event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/jpg') {
                let datae: any = event.target.files[0];
                if (datae && datae.size < 307200) {
                    const data = new FormData();
                    data.append('file', event.target.files[0]);
                    var xhr = new XMLHttpRequest();
                    xhr.open("POST", `${baseUrl}/use${URLs.uploadFiles}`);
                    xhr.upload.addEventListener("progress", function (this, evt) {
                        if (evt.lengthComputable) {
                            let percentComplete: any = evt.loaded / evt.total;
                            let df: any = percentComplete.toFixed(2) * 100;
                            datae.progress = df.toFixed(0);
                            let db = datae;
                            db.progress = df.toFixed(0);
                            _onProgress(db);
                        }
                    }, false);
                    xhr.onloadstart = function (e) {

                    }
                    xhr.onloadend = function (e) {

                    }
                    xhr.send(data);
                    xhr.onreadystatechange =
                        function () {
                            if (this.readyState === 4 && this.status === 200) {
                                var res = JSON.parse(this.response);
                                datae.response = res;
                                var de = datae
                                de.paperType = paperType;
                                _onSucess(de);
                            }
                        };
                } else {
                    event.target.value = null
                    this.setState({
                        errorMessage: 'Max size 300KB.'
                    });
                    setTimeout(() => {
                        this.setState({
                            errorMessage: ''
                        });
                    }, 3000);
                }

            } else {
                event.target.value = null
                this.setState({
                    errorMessage: 'Please select image only.'
                });
                setTimeout(() => {
                    this.setState({
                        errorMessage: ''
                    });
                }, 3000);
            }
        }
    }

    private _onDateChangeHandle = (date: any) => {
        if (date) {
            this.setState({
                epaper: { ...this.state.epaper, date: date }
            });
        }
    }

    private addPaper = () => {

        let data = JSON.parse(JSON.stringify(this.state.epaper));
        //data.date = moment(data.date).format('L');
        console.log(data);
        interface IEPaperD {
            paperType: string;
            paperUrl: string;
            thumbnail: string;
        }

        interface IEPaper {
            date: string;
            papers: Array<IEPaperD>;
            errorMessage: string;
        }


        if (data.date != '' && data.papers[0].paperUrl != '' && data.papers[0].thumbnail != '') {
            this.service.postDataOrLogout(URLs.addEPaper, data, true).then(() => {
                debugger;
                this.setState({
                    epaper: { ...this.state.epaper, papers: files },
                    DialogProps: { show: true, message: "Paper added successfully." },
                });




            });



        } else {
            this.setState({
                errorMessage: 'Please enter all the fields.'
            });
            setTimeout(() => {
                this.setState({
                    errorMessage: ''
                });
            }, 3000);

        }
    }

    render(): JSX.Element {
        console.log(this.state.epaper);

        return (
            <>

                <DatePicker
                    className={controlClass.control}
                    label="Date"
                    firstDayOfWeek={firstDayOfWeek}
                    strings={DayPickerStrings}
                    placeholder="Select a date..."
                    ariaLabel="Select a date"
                    value={new Date(this.state.epaper.date)}
                    onSelectDate={(date: any) => { this._onDateChangeHandle(date) }}
                />

                <table className="sp-table-u">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Type</th>
                            <th>Upload</th>
                            <th>Preview</th>
                            <th>Thumbnail image</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.epaper.papers.map((p) => {
                                return <tr>
                                    <td>{moment(this.state.epaper.date).format('LL')}</td>
                                    <td>{p.paperType} Edition</td>
                                    <td>
                                        <input type="file" name="photo" accept="application/pdf" id={`${p.paperType}-edition`} className="sp-hide" custom-a={p.paperType} onChange={(event: any) => this._onChangeHandler(event, this._onSucess, this._onProgress)} />
                                        <label className="sp-pointer sp-show add-paper " htmlFor={`${p.paperType}-edition`} >Add / Change</label>
                                    </td>
                                    <td>
                                        {
                                            p.paperUrl && p.paperUrl !== '' && <a href={`${baseUrl}${p.paperUrl}`} target="_blank">Show PDF</a>
                                        }
                                    </td>
                                    <td>
                                        {
                                            p.thumbnail != '' && <img src={`${baseUrl}${p.thumbnail}`} className="avatar-3" alt="" />
                                        }

                                        <input type="file" name="photo" accept="image/x-png,image/jpeg,image/png,image/jpg" id={`${p.paperType}-t`} custom-a={p.paperType} className="sp-hide" onChange={(event: any) => this._onThumbNailChangeHandler(event, this._onThumbSucess, this._onProgress)} />
                                        <label className="sp-pointer sp-show sp-center" htmlFor={`${p.paperType}-t`}>{p.thumbnail == '' ? 'Add' : 'Change'}</label>
                                    </td>
                                </tr>
                            })
                        }
                        {/* <tr>
                            <td>{moment(this.state.epaper.date).format('LL')}</td>
                            <td>Main Edition</td>
                            <td>
                                <input type="file" name="photo" accept="application/pdf" id="main-edition" className="sp-hide" onChange={(event: any) => this._onChangeHandler(event, this._onSucess, this._onProgress)} />
                                <label className="sp-pointer sp-show add-paper " htmlFor="photo">Add / Change</label>
                            </td>
                            <td>
                                {
                                    this.state.epaper.url !== '' && <a href={`${baseUrl}/${this.state.epaper.url}`} target="_blank">Click here to see preview</a>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>{moment(this.state.epaper.date).format('LL')}</td>
                            <td>Movie Edition</td>
                            <td>
                                <input type="file" name="photo" accept="application/pdf" id="main-edition" className="sp-hide" onChange={(event: any) => this._onChangeHandler(event, this._onSucess, this._onProgress)} />
                                <label className="sp-pointer sp-show add-paper " htmlFor="photo">Add / Change</label>
                            </td>
                            <td>
                                {
                                    this.state.epaper.url !== '' && <a href={`${baseUrl}/${this.state.epaper.url}`} target="_blank">Click here to see preview</a>
                                }
                            </td>
                        </tr> */}
                    </tbody>
                </table>
                <p className="sp-danger" style={{ fontSize: '20px' }}>{this.state.errorMessage}</p>
                <PrimaryButton className="sp-mt10" onClick={this.addPaper} text="Save" />


                <Dialog
                    hidden={!this.state.DialogProps.show}
                    onDismiss={this._closeDialog}
                    dialogContentProps={{
                        type: DialogType.normal,
                    }}
                    modalProps={{
                        styles: { main: { maxWidth: 450, textAlign: "center" } },
                        isBlocking: true
                    }}
                >
                    <p>{this.state.DialogProps.message}</p>
                    <DefaultButton className="sp-btn-login" onClick={this._closeDialog} text="Ok" />
                </Dialog>
            </>);
    }

}

export default AddPaper;