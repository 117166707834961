import axios from "axios";

//export const baseUrl = "http://localhost:7777";
export const baseUrl = "";
//export const baseUrl = "https://qgroup.sendshortly.com";
export default class Service {
  public get(url: string, isAuthRequired?: boolean) {
    let config;
    if (isAuthRequired) {
      config = {
        headers: {
          Authorization: `MBwebToken ${
            localStorage.getItem("MBwebToken")
              ? localStorage.getItem("MBwebToken")
              : ""
          }`,
        },
      };
    } else {
      config = {};
    }
    debugger;
    let urL = `${baseUrl}${url}`;
    debugger;
    return axios
      .get(urL, config)
      .then((res) => {
        if (res.status === 200) {
          return res;
        }
      })
      .catch((err: any) => {
        if (err && err.response) {
          return {
            status: false,
            statuscode: err.response.status,
            message: err.response.statusText,
          };
        } else {
          return {
            status: false,
            statuscode: 143,
            message: err.message,
          };
        }
      });
  }

  public post(url: string, data: any, isAuthRequired?: boolean): Promise<any> {
    let config;
    if (isAuthRequired) {
      config = {
        headers: {
          Authorization: `MBwebToken ${
            localStorage.getItem("MBwebToken")
              ? localStorage.getItem("MBwebToken")
              : ""
          }`,
        },
      };
    } else {
      config = {};
    }

    let urL = `${baseUrl}${url}`;

    return axios
      .post(urL, data, config)
      .then((res) => {
        if (res.status === 200) {
          return res;
        }
      })
      .catch((err) => {
        if (err && err.response) {
          return {
            status: false,
            statuscode: err.response.status,
            message: err.response.statusText,
          };
        } else {
          return {
            status: false,
            statuscode: 143,
            message: err.message,
          };
        }
      });
  }

  public delete(url: string, isAuthRequired?: boolean): Promise<any> {
    let config;
    if (isAuthRequired) {
      config = {
        headers: {
          Authorization: `MBwebToken ${
            localStorage.getItem("MBwebToken")
              ? localStorage.getItem("MBwebToken")
              : ""
          }`,
        },
      };
    } else {
      config = {};
    }

    let urL = `${baseUrl}${url}`;

    return axios
      .delete(urL, config)
      .then((res) => {
        if (res.status === 200) {
          return res;
        }
      })
      .catch((err) => {
        if (err && err.response) {
          return {
            status: false,
            statuscode: err.response.status,
            message: err.response.statusText,
          };
        } else {
          return {
            status: false,
            statuscode: 143,
            message: err.message,
          };
        }
      });
  }
}
