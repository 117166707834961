import React, { Component } from 'react'
import { Document, Page, pdfjs } from 'react-pdf';
import NewsService from '../Service';
import { URLs } from "../../../constants/constants";
import Util from "../../../util";
import Navbar from "../Navbar/index";
import Secondnavbar from '../Navbar/Secondnavbar';
import './styles.scss'
import moment from 'moment';
import { baseUrl } from '../../../services/services';
import Loading from '../../common/Loading';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;





export default class EPaper extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 1,
            limit: 10,
            epapers: [
                // {
                //     "_id": "61d649f9b593706864d86a85",
                //     "date": "01/03/2022",
                //     "papers": [
                //         {
                //             "_id": "61d649f9b593706864d86a87",
                //             "paperType": "Main",
                //             "paperUrl": "/uploads/fs09012022/file_164117097235901012022Shanarthitelanganareport.pdf",
                //             "thumbnail": "/uploads/fs09012022/file_164143315308403012022Shanarthitelanganareportpage00011.jpg"
                //         },
                //         {
                //             "_id": "61d649f9b593706864d86a86",
                //             "paperType": "Movie",
                //             "paperUrl": "/uploads/fs09012022/file_164117082948001012022Shanarthitelanganareport.pdf",
                //             "thumbnail": "/uploads/fs09012022/file_164143316153603012022Shanarthitelanganareportpage0004.jpg"
                //         }
                //     ],
                //     "createdAt": "2022-01-06T01:46:33.230Z",
                //     "updatedAt": "2022-01-06T01:46:33.230Z",
                //     "__v": 0
                // },
                // {
                //     "_id": "61d64c17b593706864d86a88",
                //     "date": "01/06/2022",
                //     "papers": [
                //         {
                //             "_id": "61d64c17b593706864d86a8a",
                //             "paperType": "Main",
                //             "paperUrl": "/uploads/fs09012022/file_164143395365506012022Shanarthitelanganareportcompressed.pdf",
                //             "thumbnail": "/uploads/fs09012022/file_164143404617706012022Shanarthitelanganareportpage0001.jpg"
                //         },
                //         {
                //             "_id": "61d64c17b593706864d86a89",
                //             "paperType": "Movie",
                //             "paperUrl": "/uploads/fs09012022/file_164143412098706012022Shanarthitelanganareportcompressedpages4.pdf",
                //             "thumbnail": "/uploads/fs09012022/file_164143412596806012022Shanarthitelanganareportpage0004.jpg"
                //         }
                //     ],
                //     "createdAt": "2022-01-06T01:55:35.013Z",
                //     "updatedAt": "2022-01-06T01:55:35.013Z",
                //     "__v": 0
                // }
            ],
            showPaper: false,

        }

        this.newsService = new NewsService();

    }

    componentDidMount() {

        this.setState({
            isLoading: true
        });
        this.newsService.getDataOrError(`${URLs.getEPapers}?page=${this.state.page}`, false).then((res) => {
            if (res.status) {
                debugger;
                let data = res.data;
                this.setState({
                    epapers: data,
                    isLoading: false,
                    // scrollPosition: window.scrollY,
                });
            } else {
                this.setState({
                    isLoading: false
                });
            }
        });

    }


    onPaperSelect = (p, _id) => {

        this.props.history.push(`/paper/${_id}/${p._id}`);



    }

    render() {
        console.log(this.state)
        const { isLoading } = this.state;
        return (
            <>
                {isLoading && <Loading />}
                <Navbar afterLanguageChange={this.reRender} />
                <Secondnavbar />
                <p style={{ display: 'hiddedsn' }}>h</p>
                <div className="sp-container main-container" >
                    <div className="ms-Grid e-paper1" dir="ltr">
                        <div className="ms-Grid-row">
                            {this.state.epapers.map((paper) => {
                                return <div className="ms-Grid-col thumb-e  ms-sm12 ms-md6 ms-lg6 ">
                                    {/* ms-sm12 ms-md4 */}

                                    <p className='paper-date'>{moment(paper.date).format('LL')}</p>
                                    {/* <div style={{ backgroundImage: `url(${baseUrl}${paper.papers[0].thumbnail})` }} className='thumb-back'>
                                    </div> */}

                                    <img onClick={() => { this.onPaperSelect(paper.papers[0], paper._id) }} src={`${baseUrl}${paper.papers[0].thumbnail}`} className="epaper-thumb" alt="" />
                                    {/* {
                                        paper.papers.map(p => {
                                            return <> 
                                                
                                            </>
                                        })
                                    } */}

                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
